<template>
  <div class="nav-mega-flyout-mobile">
    <div class="nav-mega-flyout-mobile__inner">
      <transition name="fade-header">
        <div v-if="activeLevel === 1" class="nav-mega-flyout-mobile__icons">
          <ul class="nav-mega-flyout-mobile__links">
            <li
              v-for="item in iconItems"
              :key="item._uid"
            >
              <SlotLink
                v-if="item.icon && item.icon !== 'user' && item.icon !== 'logout'"
                class="nav-mega-flyout-mobile__icon"
                :url="item.link.cached_url"
              >
                <AppIcon
                  :icon="item.icon"
                  :is-raw="true"
                />
              </SlotLink>
              <SlotLink
                v-if="
                  item.icon
                    && item.icon === 'user'
                "
                class="nav-mega-flyout-mobile__icon"
                :url="loggedInUser ? item.link.cached_url : 'login'"
              >
                <AppIcon
                  :icon="item.icon"
                  :is-raw="true"
                />
              </SlotLink>
              <button
                v-if="
                  item.icon
                    && item.icon === 'logout'
                    && loggedInUser
                "
                type="button"
                class="nav-mega-flyout-mobile__icon"
                @click="logoutUser()"
              >
                <AppIcon
                  :icon="item.icon"
                  :is-raw="true"
                />
              </button>
            </li>
          </ul>
        </div>
      </transition>
      <transition name="fade-header">
        <div v-if="activeLevel > 1">
          <button
            class="nav-mega-flyout-mobile__level-back"
            type="button"
            @click="flyoutStore.removeMenuBranch()"
          >
            <AppIcon
              class="nav-mega-flyout-mobile__chevron-back"
              icon="icon-right"
              :is-raw="true"
            />
            {{ useTranslation('general', 'back') }}
          </button>
        </div>
      </transition>
      <TransitionGroup
        v-if="activeLevel"
        :name="`slide-${slideDirection}`"
        tag="div"
        class="nav-mega-flyout-mobile__level"
      >
        <div v-for="activeData in activeLevelData" :key="activeData" class="nav-mega-flyout-mobile__level-sub">
          <div class="nav-mega-flyout-mobile__level-items">
            <AppNavListMobile
              :data="activeData"
            />
          </div>
          <BlockBannerCta
            v-if="activeData.content && activeData?.content[0]?.component === 'BlockBannerCta'"
            class="nav-mega-flyout-mobile__level-content"
            :data="activeData?.content[0]"
          />
          <BlockProductsFlyout
            v-if="activeData.content && activeData?.content[0]?.component === 'BlockProductsFlyout'"
            :data="activeData?.content[0]"
          />
        </div>
      </TransitionGroup>
    </div>
    <div class="nav-mega-flyout-mobile__close">
      <AppIcon
        icon="close-box"
        :is-raw="true"
        @click="flyoutStore.closeFlyout()"
      />
    </div>
  </div>
</template>

<script setup>
/*
  Get settigns store
*/
const iconItems = computed(() => getStoryblokSetting('navigation', 'header_icon_links'));

/*
  Get flyout store
*/
const flyoutStore = useFlyoutStore();
const activeLevel = computed(() => flyoutStore.getActiveLevel);
const activeLevelData = computed(() => [flyoutStore.getMenuBranch]);
const slideDirection = computed(() => flyoutStore.getSlideDirection);

/*
  Get user to check if user is logged in
*/
const { logout } = useStrapiAuth();
const loggedInUser = useStrapiUser();
const cartStore = useCartStore();

const logoutUser = () => {
    logout();
    cartStore.revokeCart();
    navigateTo('/');
};
</script>

<style lang="scss">

.nav-mega-flyout-mobile {
    @include remove-scrollbars();
    @include z-index('flyoutMobile');

    position: fixed;
    top: 0;
    width: 100%;
    height: 100vh;
    background-color: $C_WHITE;
    overflow-y: auto;
}

.nav-mega-flyout-mobile__inner {
    @include grid-layout();

    position: relative;
    padding: 0 var(--page-padding);
}

.nav-mega-flyout-mobile__level {
    @include grid-columns(2, 10);

    position: relative;
    height: fit-content;
}

.nav-mega-flyout-mobile__level-sub {
    @include grid-columns(2, 10);
}

.nav-mega-flyout-mobile__level-back {
    @include grid-columns(2);
    @include typo-font('regular');
    @include typo-size('navLevel3');
    @include z-index('header');

    position: absolute;
    display: flex;
    align-items: center;
    margin-top: 37px;
    color: $C_PRIMARY;
    transition: color 0.2s;

    .nav-mega-flyout-mobile__chevron-back {
        width: 16px;
        height: 16px;
        margin-right: 10px;
        color: currentColor;
        transform: rotate(180deg);

        path {
            stroke: currentColor;
        }
    }

    &:hover {
        color: $C_SECONDARY;
    }

    @include mobile {
        margin-top: 47px;
    }
}

.nav-mega-flyout-mobile__level-items {
    @include grid-columns(2, 10);

    position: relative;
}

.nav-mega-flyout-mobile__level-content {
    .block-banner-cta {
        min-height: unset;
        max-height: 250px;

        @include mobile {
            max-height: unset;
        }
    }
}

.nav-mega-flyout-mobile__icons {
    @include grid-columns(2, 10);
    @include z-index('header');

    position: absolute;
    top: 88px;

    .nav-mega-flyout-mobile__links {
        display: flex;
        align-items: center;
    }

    li {
        display: flex;
        align-items: center;
    }
    .nav-mega-flyout-mobile__icon {
        margin-right: 30px;

        @include mobile {
            margin-right: 20px;
        }
    }

    .app-icon {
        width: 32px;
        height: 32px;
        transition: transform 0.1s ease-in-out;

        &:hover {
            transform: scale(1.08);
        }

        @include mobile {
            width: 28px;
            height: 28px;
        }
    }

    @include mobile {
        top: 39px;
    }

}

.nav-mega-flyout-mobile__close {
    position: absolute;
    top: 80px;
    right: 40px;
    color: $C_PRIMARY;

    &:hover {
        cursor: pointer;
    }

    svg {
        height: 61px;

        @include mobile {
            height: 36px;
        }
    }

    @include mobile {
        top: 35px;
        right: 35px;
    }
}
</style>
